import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Hero from './Hero';
import TopBar from './TopBar';
import Features from './Features';
import Testimonials from './Testimonials';
import Highlights from './Highlights';
import FAQ from './faq';
import Footer from './Footer'


export default function Landing(props) {
  const {mode, toggleColorMode} = props

  return (
    <>
      <CssBaseline />
      <TopBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box sx={{ bgcolor: 'background.default' }}>
     
        {/*<LogoCollection />*/}
        <Features />
        <Divider />
        <Testimonials />
        <Divider />
        <Highlights />
        <Divider />
        {/*<Pricing />*/}
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
      </Box>
      {/*<ToggleCustomTheme
        showCustomTheme={showCustomTheme}
        toggleCustomTheme={toggleCustomTheme}
      />*/}
    </>
  );
}