import * as React from 'react';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, IconButton, ImageListItem, ImageListItemBar, alpha } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel'


const items = [
    {
      title: 'Elevate Your Digital Experience with UI/UX',
      description:
        "Transform your digital presence into a powerful user experience that drives growth and builds lasting customer relationships. Contact us today, and let's create something extraordinary together!",
      image: "https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_1.jpg?alt=media&token=e7d4d2e1-d66b-448b-85b5-be6fd4be54c7",
    },
    {
      title: "Video Games",
      description:
        "Your game deserves to be played, enjoyed, and remembered. With us, you're not just hiring a service; you're partnering with a team that shares your dream. We're here to ensure your game makes an unforgettable impact in the gaming world.",
      image: "https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_2.jpg?alt=media&token=10b2c907-45ed-4488-b4a7-5ff3b33610b5",
    },
    {
      title: 'Ready to Make Your Mobile Vision a Reality?',
      description:
        "Let's collaborate to create a mobile app that not only meets but surpasses your expectations. With us, your app is destined for the top charts. Our commitment to innovation, quality, and client satisfaction makes us the perfect partner for your mobile app development needs.",
      image: "https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_3.jpg?alt=media&token=7ec5cbee-0633-4de5-847d-299727a06c50",
    },
    {
      title: "Step into the Future with Virtual Reality",
      description:"Imagine the possibilities when the only limit is your imagination. With Leidenfrost, step into a world where virtual becomes reality. Our mission is to help you harness the transformative power of VR to create deep, meaningful connections and experiences that leave a lasting impact.",
      image:"https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_4.jpg?alt=media&token=82c4f363-0832-4be3-a10f-8afedd34deb3"
    },
    {
      title: 'Rendering',
      description: "With Leidenfrost, your project isn't just another job—it's an opportunity to create something truly remarkable. Our renderings not only showcase your ideas in the best light but also help communicate your vision more effectively to clients, stakeholders, and the public.",
      image:"https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_5.jpg?alt=media&token=8fe8405d-d5b2-4f0b-9911-3e155fda7b11"
    }
   
  ];

export default function Hero() {

  const [background, setBackground] = React.useState(items[0])
  const [count, setCount] = React.useState(0)
  
  React.useEffect(()=>{
    const interval = setInterval(()=>{
      console.log("count: "+count)

      if(count < items.length){
        setCount(count + 1) 
        setBackground(items[count])
      }else{
        setCount(0) 
        setBackground(items[0])
      }
      
      
    },5000)

    return () => clearInterval(interval);
  },[count])

  return (
    <Box id="hero" sx={(theme) => ({
      width: '100%',
      backgroundImage:"url("+background.image+")",
      backgroundRepeat: 'no-repeat',
      height:"100vh",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      
    })}
  >
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        pt: { xs: 14, sm: 40 },
        pb: { xs: 8, sm: 12 },
      }}
    >
      <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '60%' },backdropFilter: "blur(3px)", }}>
        <Typography
          component="h1"
          variant="h1"
          color="#FFF"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignSelf: 'left',
            textAlign: 'left',
            fontWeight:"bolder"
          }}
        >
          {background.title}
          
        </Typography>
        <Typography variant="h6" textAlign="left" color="#FFF">
          {background.description}
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignSelf="left"
          spacing={1}
          useFlexGap
          sx={{ pt: 2, width: { xs: '100%', sm: '60%' } }}
        >
        
          <Button variant="contained">
            Start now
          </Button>
        </Stack>
        <Typography variant="caption" color="#FFF" textAlign="left" sx={{ opacity: 0.9 }}>
          By clicking &quot;Start now&quot; you agree to our&nbsp;
          <Link href="#" color="red">
            Terms & Conditions
          </Link>
          .
        </Typography>
      </Stack>
    </Container>
  </Box>
  );
}