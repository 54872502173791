import React, { createContext, useContext, useEffect,useState } from "react";
//import firebaseConfiguration from "FirebaseConfiguration";
//import { getAuth, onAuthStateChanged  } from "firebase/auth";
//const auth = getAuth(firebaseConfiguration);
const AuthContext = createContext();
export const UserAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState({});
  
    useEffect(() => {
        /*const unsubscribe = onAuthStateChanged(auth,(user) => {    
            setCurrentUser(user);
        })

        return () => void unsubscribe();*/
    }, [currentUser]);
  
    return (
      <AuthContext.Provider
        value={{
          currentUser,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };
export default AuthContextProvider;