import React from "react";
import {
  DashboardView,
  LandingView,
  NotFoundView,
TermsView,
LoginView
} from "./views";

import { Navigate, Routes, Route } from "react-router-dom";

import { UserAuth } from "./AuthContextProvider";

export default function Router() {
  const { currentUser } = UserAuth();
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" replace />} />

      <Route
        path="/dashboard"
        element={
          currentUser ? <DashboardView /> : <Navigate to="/login" replace />
        }
      >
        
      </Route>


      <Route path="/home" element={<LandingView />} />
      
      <Route path="/login" element={<LoginView />}/>

      <Route path="/terms" element={<TermsView />}/>
      
      <Route path="/404" element={<NotFoundView />} />
    </Routes>
  );
}
