import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const userTestimonials = [
  {
    avatar: <Avatar alt="Cristian S" src="/static/images/avatar/1.jpg" />,
    name: 'Cristian S.',
    occupation: 'Software Engineer',
    mainSkill: 'IOS',
    testimonial:
      "I’m constantly developing software. "+
      "Usually I use Unity3D, Autodeks Maya, and design tools like Gravit Designer for create fantastic 3D enviroments for hobbie. "+
      "In my last job I was working for big e-commerce like ShakeShack, CK, Panda Express, Petco for the region of Latin America. Recently I've been working with ChatGPT and Open IA."
  },
  {
    avatar: <Avatar alt="Emmanuel C." src="/static/images/avatar/2.jpg" />,
    name: 'Emmanuel V.',
    occupation: 'Software Developer',
    mainSkill: 'React',
    testimonial:
      "Being passionate and eager to contribute using my experience "+
      "and knowledge, I have 3 years of experience working as a"+
      "freelancer and 2 working professionally. "+
      "I am committed to following the best coding practices to"+
      "produce and deliver maintainable and readable code, as well"+
      "as following Figma designs to the letter to deliver to end users"+
      "delightful experiences.",
  },
  {
    avatar: <Avatar alt="José L." src="/static/images/avatar/3.jpg" />,
    name: 'José L.',
    occupation: 'DevOps Engineer',
    mainSkill: 'K8s',
    testimonial:
      "SRE specialized in Linux, high availability and infrastructure monitoring. " +
      "RELIABILITY, SCALABILITY and SECURITY are my focus. " +
      "Load balancing, DNS, firewalls, optimization slow queries and automation. " +  
      "Cloud & On promise: GCP, AWS, Kubernetes and Docker Swarm. " + 
      "SQL (PostgreSQL, MySQL, SQL Server) and NoSQL (MongoDB, Firebase) management. "
  },
];

const spanStyle = {
  fontWeight: 'bolder',
  fontSize: '2em',
  fontStyle: 'oblique',
  marginTop: '0.1em',
  color: 'darkkhaki'
}

export default function Testimonials() {
  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Our Team
        </Typography>
        <Typography variant="body1" color="text.secondary">
          At Leidenfrost, we are a dynamic and talented group of developers, 
          designers, and innovators committed to bringing your ideas to life. 
          With years of collective experience and a passion for pushing the boundaries of technology, 
          we specialize in crafting bespoke solutions that drive success and transform industries.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
               <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                <span style={spanStyle}>
                  {testimonial.mainSkill}
                </span>
              </Box>
              <CardContent>
                <Typography variant="body2" color="text.secondary" style={{ wordBreak: 'break-word' }}>
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
             
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
