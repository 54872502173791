import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Alert, FormControl, FormControlLabel, FormLabel, Grid, Paper, Snackbar } from '@mui/material';
import Stack from '@mui/material/Stack';
import { CheckBox } from '@mui/icons-material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="www.leidenfrost.io">
        Leidenfrost.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn(props) {
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const [snackbarState, setSnackbarState] = React.useState({open:false,message:"",status:"info"});
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    if (emailError || passwordError) {
      event.preventDefault();
      return;
    }
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const validateInputs = () => {
    const email = document.getElementById('email');
    const password = document.getElementById('password');

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return isValid;
  };

  return (
    
    <Grid container component="main" sx={{ height: '100vh' }}>
    <CssBaseline />
    <Grid
      item
      xs={false}
      sm={4}
      md={7}
      sx={{
        backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/future.jpg?alt=media&token=155aafbd-f8bd-45d8-98bf-33e4285f3b3f)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
    </Grid>
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' }}}>
        <Box
            alignSelf= 'center'
            component="img"
            sx={{
              height: 120,
              width: 300,
            }}
            alt="officia logo."
            src="https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/leidenfrost.svg?alt=media&token=aa8b35e7-10dc-4f45-9530-63fef6768eca"
          />
        </Stack>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} >
            <FormControl >
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                sx={{mt:1}}
                error={emailError}
                helperText={emailErrorMessage}
                id="email"
                type="email"
                name="email"
                placeholder="your@email.com"
                autoComplete="email"
                autoFocus
                required
                variant="outlined"
                color={emailError ? 'error' : 'primary'}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                sx={{mt:1}}
                error={passwordError}
                helperText={passwordErrorMessage}
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                autoFocus
                required
                variant="outlined"
                color={passwordError ? 'error' : 'primary'}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={validateInputs}
            >
              Sign in
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot your password?
                </Link>
              </Grid>
              <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackbarState.open} onClose={handleClose}>
                <Alert
                  onClose={handleClose}
                  severity={snackbarState.status}
                  variant="filled"
                >
                  {snackbarState.message}
                </Alert>
              </Snackbar>
            </Grid>
          </Stack>
          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Box>
    </Grid>
  </Grid>
  );
}